import {
  Menu, MenuItem
} from '../Models/Menu';

const menu: Menu = new Menu([
  {
    id: 1,
    name: "ACCUEIL",
    link: "/",
    icon: "home",
    subMenu: []
  },
  {
    id: 2,
    name: "COTISATIONS",
    link: "",
    icon: "dollar-sign",
    subMenu: [
      {
        id: 2,
        name: "Liste",
        link: "/cotisation/list",
        icon: "",
        subMenu: []
      }, 
      {
        id: 2,
        name: "Déposer",
        link: "/cotisation/deposer",
        icon: "",
        subMenu: []
      }, 
    ]
  },
  {
    id: 3,
    name: "PARAMETRES",
    link: "/parametres",
    icon: "settings",
    subMenu: []
  }
]);


export default menu