import { FC, useEffect, useState, useRef } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import ContributionService from './service';

//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate
} from "react-router-dom";

// React - Bootstrap
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup';

//Redux 
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { PayPalScriptProvider, PayPalButtons, usePayPalHostedFields, usePayPalScriptReducer } from "@paypal/react-paypal-js";


//Alert
import Alert from '../../Components/Alert';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 

//Utils
import axiosInstance from '../../Utils/axios_manager';

interface ContributionFormData {
    amount: number | null;
}

const GiveContribution: FC = () => {  

    const contributionFormRef = useRef< FormikProps< ContributionFormData >>(null);


    let idToModInit: any = null;
    const { t } = useTranslation();
    const [ contributions, setContributions ] = useState( [] );
    const [key, setKey] = useState('all');
    const [ filteredAgents, setFilteredAgents ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const contributionService = new ContributionService();

    const [keys, setKeys] = useState([
        {
            key: 'all',
            label: `${t('Tout')}` 
        },
        {
            key: 'fulltime',
            label: `${t('Temps plein')}`
        },
        {
            key: 'season',
            label: `${t('Saisonières')}`
        },
        {
            key: 'contract',
            label: `${t('Contractuels')}`
        },
        {
            key: 'stage',
            label: `${t('Stagiaires')}`
        }
    ]);

    const navigate = useNavigate();

    let editMember = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/member/edit/'+id.toString());
        setLoading(false);
    }

    const getContributions = () => {
        setLoading(true);
        contributionService.getContributions().then(async function (response: any) {
            console.log(response);
            setContributions(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

 

    useEffect( function() { 
        getContributions();
    }, []);

    const columns = [
        {
            name: `${t('Montant')}`,
            selector: (row: any) => row.amount,
        }, 
        {
            name: `${t("Date de paiement")}`,
            selector: (row: any) => <Moment format="DD/MM/YYYY hh:mm:ss">
                                        {row.created_at}
                                    </Moment>
        } 
    ];
    
    const reload = () => {
        getContributions();
    }

    const onCreateOrder = (data: any,actions: any) => {
        return new Promise((resolve:(value: string) => any, reject) => {

            if (contributionFormRef.current !== null) {
                contributionService.initializePaypalContribution(contributionFormRef.current?.values).then(async function (response: any) {
                    console.log(response); 
                    window.localStorage.setItem('_order_id', response.data.order_id);
                    const order: any = actions.order.create({
                        purchase_units: [
                            {
                                reference_id: response.data.order_id,
                                amount: {
                                    value: contributionFormRef.current?.values.amount ,
                                },
                            },
                        ],
                    });

                    console.log(order);
                    const order_copy = order;
                    order_copy.then(
                        (val:any) => { 
                            if (order !== null && order !== '') {

                                contributionService.updatePaypalContribution({
                                    order_id: response.data.order_id,
                                    paypal_order_id: val
                                }).then(async function (response: any) {
                                    console.log(response); 
                                    resolve(order);
                                })
                                  .catch(function (error: any) {
                                    console.log(error); 
                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        type: 'error',
                                                        title: 'Erreur',
                                                        message: 'Une erreur d\'initialisation de votre paiement, réessayez s\'il vous plaît.',
                                                        actionText: 'Ok',
                                                        action: () => { 
                                                            if (contributionFormRef.current !== null) {
                                                                contributionFormRef.current.values.amount = null;
                                                            }
                                                            onClose();
                                                         } 
                                                    }}
                                                />
                                          )}});
        
                                });
        
                            }

                        }
                    )
                     

                })
                  .catch(function (error: any) {
                    console.log(error); 
                    confirmAlert({
                        overlayClassName: "alert-overlay",
                        customUI: ({ onClose }) => {
                          return (
                                <Alert 
                                    alert={{
                                        type: 'error',
                                        title: 'Erreur',
                                        message: 'Une erreur d\'initialisation de votre paiement, réessayez s\'il vous plaît.',
                                        actionText: 'Ok',
                                        action: () => { 
                                            if (contributionFormRef.current !== null) {
                                                contributionFormRef.current.values.amount = null;
                                            }
                                            onClose();
                                         } 
                                    }}
                                />
                          )}});

                });
            }

        });
        
    }

    const onApproveOrder = (data: any,actions: any) => {

        let order_id = window.localStorage.getItem('_order_id'); 

        return new Promise((resolve:(value: void) => any, reject) => { 

            return actions.order.capture().then((details: any) => {
                    const name = details.payer.name.given_name;

                    contributionService.updatePaypalContribution({
                        order_id: order_id 
                    }).then(async function (response: any) {
                        console.log(response); 
                        confirmAlert({
                            overlayClassName: "alert-overlay",
                            customUI: ({ onClose }) => {
                              return (
                                    <Alert 
                                        alert={{
                                            type: 'success',
                                            title: 'Succès',
                                            message: 'Votre cotisation a été enrégistré avec succès, merci.',
                                            actionText: 'Ok',
                                            action: () => { 
                                                if (contributionFormRef.current !== null) {
                                                    contributionFormRef.current.values.amount = null;
                                                }
                                                onClose();
                                             } 
                                        }}
                                    />
                              )}});

                        resolve();
                    })
                      .catch(function (error: any) {
                        console.log(error); 

                        confirmAlert({
                            overlayClassName: "alert-overlay",
                            customUI: ({ onClose }) => {
                              return (
                                    <Alert 
                                        alert={{
                                            type: 'error',
                                            title: 'Erreur',
                                            message: 'Une erreur de validation de votre paiement, consultez nos administrateurs s\'il vous plaît.',
                                            actionText: 'Ok',
                                            action: () => { 
                                                if (contributionFormRef.current !== null) {
                                                    contributionFormRef.current.values.amount = null;
                                                }
                                                onClose();
                                             } 
                                        }}
                                    />
                              )}});

                    });
                     
            }).catch((error: any) => {

                confirmAlert({
                    overlayClassName: "alert-overlay",
                    customUI: ({ onClose }) => {
                      return (
                            <Alert 
                                alert={{
                                    type: 'error',
                                    title: 'Erreur',
                                    message: 'Une erreur de validation de votre paiement, consultez nos administrateurs s\'il vous plaît.',
                                    actionText: 'Ok',
                                    action: () => { 
                                        if (contributionFormRef.current !== null) {
                                            contributionFormRef.current.values.amount = null;
                                        }
                                        onClose();
                                     } 
                                }}
                            />
                      )}});

            })

        });
  
    }

    // const createOrder: any = async (data: any) => {

    //     if (contributionFormRef.current !== null) {

    //         // return contributionService.initializePaypalContribution(contributionFormRef.current?.values).then(async function (response: any) {
    //         //     console.log(response); 
    //         //     window.localStorage.setItem('_order_id', response.data.order_id);
    //         //     return response.data.data.id;
    //         // })
    //         //   .catch(function (error: any) {
    //         //     console.log(error); 
    //         // });

    //         const paypalOrder: any = await createPaypalOrder();

    //         console.log(paypalOrder);
 
    //         return paypalOrder.id;
    
    //     } else {
    //         return await new Promise(
    //             (resolve: any, reject) => {  resolve()  });
    //     }

        
    // }

    // const onApprove: any = async (data: any) => {

    //     let order_id = window.localStorage.getItem('_order_id');

    //     if (order_id !== null && order_id !== '') {

    //         return contributionService.checkPayment({
    //             order_id: order_id
    //         }).then(async function(response: any) {
    //             console.log(response); 
    //             // window.localStorage.setItem('_order_id', response.data.order_id);
    //             // return response.data.data.id;
    //         })
    //           .catch(function (error: any) {
    //             console.log(error); 
    //         });

    //     } else {

    //         return await new Promise(
    //             (resolve: any, reject) => {  resolve()  });

    //     }

    // }


    const createPaypalOrder: any = async () => {
        return await new Promise(
            (resolve: any, reject) => {
  
                contributionService.createPaypalOrder({
                    "intent": "CAPTURE",
                    "purchase_units": [
                        {
                            "reference_id": 1,
                            "amount": {
                                "currency_code": "USD",
                                "value": "1"
                            }
                        }
                    ],
                    "payment_source": {
                      "paypal": { 
                        "experience_context": {
                          "payment_method_preference": "IMMEDIATE_PAYMENT_REQUIRED",
                          "brand_name": "La Potencielle",
                          "locale": "en-US",
                          "landing_page": "NO_PREFERENCE",
                          "shipping_preference": "GET_FROM_FILE",
                         
                          "user_action": "PAY_NOW",
                          "return_url": "https://example.com/returnUrl",
                          "cancel_url": "https://example.com/cancelUrl"
                        }
                      }
                    }
                  }, {
                    "Content-Type": "application/json", 
                    "Authorization": "Bearer A21AAK9XzSKmK8oIHPbzND8hx9TUMafsRETqsjt3dwcIjstRG17-z2uv-qjhnq-VnRH5tYqg5o4RTcHxh_LdMNEofAIhfT9pg"
                  }).then(async function (response: any) {
                    console.log(response); 
                    resolve(response.data);
                })
                .catch(function (error: any) {
                    console.log(error); 
                });
               
                  
               
              }   
          );
    }
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                        <div className="btn-list">
                                    
                                            <Link type="button" to="/cotisation"
                                                className="btn waves-effect waves-light btn-danger">
                                                { t('Retour') }    
                                            </Link>
                                        </div>
                                        
                                        <br />
                                        <br />
                                        <div className='row'>
                                            <div className='col-md-2'></div>
                                            <div className='col-md-8'> 
                                            <p className='text-center'>Le montant à cotiser doit être entré en équivalence dollars</p>
                                        <Formik
                                            innerRef={contributionFormRef}
                                            initialValues={{
                                                amount: null
                                            }}
                                            validationSchema={
                                                yup.object().shape({
                                                    amount: yup
                                                        .number() 
                                                        .required(
                                                            `${t('Ce champ est obligatoire')}`
                                                        )
                                                        .test('positiveChecker', "Amount must be superior to 0", async value => (value ?? 0) > 0 
                                                        ),
                                                })
                                            }
                                            onSubmit={async (
                                                values,
                                                actions
                                            ) => {
                                                console.log(values);
                                                console.log(actions);
                                             }}
                                            >
                                            {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                            <Form>
                                            <div className="row"> 
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label className="text-dark" htmlFor="password">
                                                            {
                                                                t('Montant à déposer')
                                                            }
                                                        </label>
                                                        <div className='input-group'>
                                                            <input  className={`form-control ${ errors.amount && touched.amount ? "is-invalid":""}`}
                                                                id="amount"
                                                                type='number'
                                                                name="amount"
                                                                placeholder={ `${t('Montant à déposer')}` }
                                                                onChange={handleChange('amount')}
                                                                onBlur={handleBlur('amount')}
                                                                value={values.amount ?? ''}
                                                            />
                                                            <span className="input-group-append">
                                                                <button onClick={event => { }} className="btn btn-outline-primary border-left-0 border" type="button">
                                                                    $ 
                                                                </button>
                                                            </span>

                                                            { errors.amount && touched.amount && errors.amount && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.amount && touched.amount && errors.amount }
                                                        </div> }
                                                        </div>
                                                    
                                                        
                                                        
                                                        
                                                    </div>
                                                </div>     
                                                <div className="col-lg-12 text-center">
                                                { isValid && dirty  ?  <PayPalScriptProvider options={{
                                                    clientId: "AT3M6H7nxS92flEl1RAXpUWuxzXJiFMTHi0pEXqHo1546CT2cKhbDgktktWpWsGd1frbuh1JrSTjyjhP",
                                                    currency: "USD", components: "buttons"  }}>

                                                     <PayPalButtons disabled={ ( !isValid && !dirty ) }
                                                        createOrder={onCreateOrder}
                                                        onApprove={onApproveOrder}
                                                        style={{ layout: "horizontal" }}
                                                    />

                                                </PayPalScriptProvider> : 
                                                    <button type="submit" 
                                                        // loading={loading}
                                                        // disabled={!isValid} 
                                                        disabled={!dirty && !isValid}
                                                        // onClick={event =>  handleSubmit}
                                                        className="btn btn-block btn-primary btn-connexion" >
                                                        {
                                                            t('Payer')
                                                        }
                                                        {
                                                            loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        }
                                                    </button> }
                                                </div> 
                                            </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    </div>
                                        </div>
                                                        
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default GiveContribution