import { FC, useEffect, useState } from 'react';
import Breadcrumb from '../../Components/Breadcrumb';

//Redux 
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
 
import { RootState } from '../../Redux/store';
import DashboardService from './service';

import {
    Link, useNavigate
} from "react-router-dom";
import DataTable from 'react-data-table-component';

// the hook
import { useTranslation, withTranslation } from 'react-i18next';
import i18next from 'i18next';

//Moment react
import Moment from 'react-moment';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar, Line } from 'react-chartjs-2';
  import { faker } from '@faker-js/faker';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );
  

interface DashboardMonthSell { 
    dates?: any;
    totals?: any; 
}

interface DashboardData { 
    nbreVente?: any;
    nbreProduit?: any; 
    nbreTotalProduit?: any;
    ventesTotal?: any;
    capitalTotal?: any;
    interetTotal?: any;
}
 
const Dashboard: FC = () => {  

    const { t, i18n } = useTranslation();

    let idToModInit: any = null;

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: `${t('Vente du mois')}`,
          },
        },
    };

    const dashboardService = new DashboardService();
    const user = useAppSelector((state: RootState) => state.users.user );

    const localStorage = window.localStorage;

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [ idToMod, setIdToMod ] = useState(idToModInit);
    

    let dashboardDatainitial: DashboardData = { 
        nbreVente: null,
        nbreProduit: null, 
        nbreTotalProduit: null,
        ventesTotal: null,
        capitalTotal: null,
        interetTotal: null  }

    const dashboardMonthSellsInit = {
        dates: Array(),
        totals: Array()
    };

    const [ lastRegisteredMembers, setLastRegisteredMembers ] = useState([]);
 
    let labels: Array<String> = [ ];
 
    let init_data: Array<String> = [ ];  
    let data = {
        labels,
        datasets: [
          {
            label: `${t('Ventes par jour')}`,
            data: init_data,
            backgroundColor: 'rgba(210, 212, 98, 1)',
          }, 
        ],
    };

    let editMember = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/member/edit/'+id.toString());
        setLoading(false);
    }

    const columns = [
        {
            name: `${t('Prénom')}`,
            selector: (row: any) => row.firstname,
        },
        {
            name: `${t('Nom')}`,
            selector: (row: any) => row.lastname,
        },
        {
            name: `${t('Email')}`,
            selector: (row: any) => row.email,
        },
        {
            name: `${t('Date d\'inscritpion')}`,
            selector: (row: any) => <Moment format="DD/MM/YYYY hh:mm:ss">
                                        {row.created_at}
                                    </Moment>
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => editMember(Number(row.id))}
                        className="btn waves-effect waves-light btn-success">
                            <i className='fas fa-eye'></i>
                            {
                                loading && idToMod == Number(row.id) && <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                    </button>
                </>
            )
        }
    ];
 

 

 
    const getLastRegisteredMembers = () => {
            setLoading(true);
            dashboardService.getLastRegisteredMembers().then(async function (response: any) {
                console.log(response);
                setLastRegisteredMembers(response.data);
                setLoading(false); 
            })
              .catch(function (error: any) {
                console.log(error);
                setLoading(false); 
             });
    }
 
    
    useEffect(() => {

        // getLastRegisteredMembers();

        // getDashboardInfo();

        // getDashboardMonthSells();

    }, []);

    return (
        <> 
            <Breadcrumb />
            {/* Work buttons */}
            
            <div className="container-fluid"> 

            <div className="row"> 

                    { ( !user?.password_changed || !user?.photo_changed) && <div className="col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">{
                                    t('Actions requises')
                                }</h4>

                                {
                                    !user?.password_changed && <div style={{ paddingBottom: '25px' }} className="alert alert-danger" role="alert">
                                    Vous utilisiez toujours le mot de passe par défaut, vous devez changer votre mot de passe pour plus de sécurité
                                    <Link to={ '/parametres' } style={{ float: 'right'  }} className='btn btn-danger'>
                                        Changer mot de passe
                                    </Link>
                                     </div>
                                }

                                {
                                    !user?.photo_changed && <div style={{ paddingBottom: '25px' }} className="alert alert-danger" role="alert">
                                    Pour plus de crédibilité mettez votre propre photo au profile
                                    <Link to={ '/parametres/profile' } style={{ float: 'right'  }} className='btn btn-danger'>
                                        Changer photo
                                    </Link>
                                     </div>
                                }

                               
                            </div>
                        </div>
                    </div> }
                
                </div> 

 
                <div className="row">
                     
                    <div className="col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">{
                                    t('Bienvenue')
                                } {user?.firstname + ' ' + user?.lastname}</h4>
                                {/* <ul className="list-inline text-center mt-5 mb-2">
                                    <li className="list-inline-item text-muted font-italic">
                                        {
                                            t('Ventes du mois')
                                        }
                                    </li>
                                </ul> */}
                                <p className='text-center'>
                                    <img width={200} height={200} className='img-responsive' src="assets/images/logo.jpg" alt="" />
                                </p>
                            </div>
                        </div>
                    </div>
             
                </div> 


                
      
               
            </div>
        </>
    );
}

export default Dashboard