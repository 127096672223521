import * as React from 'react';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate
} from "react-router-dom";
import { withTranslation } from 'react-i18next';

//components
import Alert from './Components/Alert';

// Redux
import { useAppDispatch } from './Hooks/customSelector';
import { setUser } from './Redux/Reducers/userReducer';

// Models
import Admin from './Models/Admin';

// *****Pages******* 

// Core
import AdminCore from './Core/Admin';
import Auth from './Core/Auth';

// Track
import Track from './Pages/Track';

// 404 
import NotFound from './Pages/NotFound';

// Dashboard
import Dashboard from './Pages/Dashboard';

// Login
import Login from './Pages/Login';
 


import Member from './Pages/Member';
import MemberList from './Pages/Member/list';
import MemberEdit from './Pages/Member/edit';
import Parameter from './Pages/Parameters';
import Contribution from './Pages/Contribution';
import ContributionList from './Pages/Contribution/list';
import GiveContribution from './Pages/Contribution/give';

//


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
type ProtectedRouteProps = { 
  outlet: JSX.Element;
};

function ProtectedRoute({ outlet }: ProtectedRouteProps) {
  const dispatch = useAppDispatch();
  const user = window.localStorage.getItem('app_user');
  if( user !== null && user !== undefined ) {
    dispatch( setUser( Admin.fromJson(  JSON.parse(user)  ) ) ); 
    return outlet;
  } else {
    window.location.href = "/auth"
    return <Navigate to={{ pathname: '/auth' }}  replace={true} />;
  }
};

// const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
//   isAuthenticated: true,
//   authenticationPath: '/auth',
// };

const App = () => {

  return (
    // basename="/poten"
    
      <Router  >
        <Track/>
        
        <Routes>
          <Route path="/" element={ <AdminCore />} >

            <Route index element={<ProtectedRoute  outlet={ <Dashboard />} />} />

           {/* Parameters */}
           <Route path="parametres/:defaultTab" element={<ProtectedRoute outlet={ <Parameter />} />} />
           <Route path="parametres" element={<ProtectedRoute outlet={ <Parameter />} />} />

           
            {/* Contribution Menu */}
            <Route path="cotisation" element={<ProtectedRoute  outlet={ <Contribution />} />} >
              <Route index element={<ProtectedRoute  outlet={ <ContributionList />} />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <ContributionList />} />} />
              <Route path="deposer" element={<ProtectedRoute  outlet={ <GiveContribution  />} />} />
              {/* <Route path="edit/:id" element={<ProtectedRoute  outlet={ <MemberEdit />} />} />  */}
            </Route>

          </Route>  
          <Route path="/auth" element={ <Auth />} >
            <Route index element={<Login />} />
          </Route> 
          <Route path="*" element={ <NotFound />} />
        </Routes>
      </Router> 
  );
}

export default  App;
