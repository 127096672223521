export default class Admin {
    public id?: number;
    public firstname?: string;
    public lastname?: string;
    public photo?: string;
    public photo_changed?: boolean;
    public password_changed?: boolean;
    public token?: string;
   


    static fromJson(parsedJson: Record<string, any>): Admin {
        return new Admin(
            Number(parsedJson['id']),
            parsedJson['firstname'],
            parsedJson['lastname'],
            parsedJson['photo'],
            parsedJson['photo_changed'],
            parsedJson['password_changed'],
            parsedJson['token']
        );
    }

    static toJson(user: Admin): Record<string, any>  {
        return  {
            "id": user.id,
            "firstname": user.firstname,
            "lastname": user.lastname,
            "photo": user.photo,
            "photo_changed": user.photo_changed,
            "password_changed": user.password_changed,
            "token": user.token
        }
    }
  
    constructor(id: number, firstname: string, lastname: string, photo: string, photo_changed: boolean, password_changed: boolean,  token: string  ) {
            this.id = id;
            this.firstname = firstname;
            this.lastname = lastname;
            this.photo = photo;
            this.photo_changed = photo_changed;
            this.password_changed = password_changed;
            this.token = token;
 
    }

  }