import User from '../../Models/User';
import Member from '../../Models/Member';
import axiosInstance from '../../Utils/axios_manager';

import axios from 'axios';

export default class ContributionService {
  
    constructor() {  }

    public getContributions =  async () => {
        return await axiosInstance.get('/get_contributions.php');
    }

    public initializePaypalContribution =  async (data: Record<string, any> ) => {
        return await axiosInstance.post('/initialize_paypal_contribution.php', data);
    }

    public checkPayment =  async (data: Record<string, any> ) => {
        return await axiosInstance.post('/check_payment.php', data);
    }

    public createPaypalOrder = async (data: Record<string, any>, headers: Record<string, any>) => {
        return await axios.post('https://api-m.sandbox.paypal.com/v2/checkout/orders',  data,{
            headers: headers
        });
    }

    public updatePaypalContribution =  async (data: Record<string, any> ) => {
        return await axiosInstance.post('/update_contribution.php', data);
    }

}