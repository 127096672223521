import Member from '../../Models/Member';
import axiosInstance from '../../Utils/axios_manager';

export default class  ParameterService {
  
    constructor() {  }

    public getUser = async () => {
        return await axiosInstance.get('/get_user.php');
    }

    
    public verifyEmail =  async (email: string) => {
        return await axiosInstance.get('/verify_email.php' + `?email=${email}`);
    }

    public verifyPhoneNumber =  async (email: string) => {
        return await axiosInstance.get('/verify_phone_number.php' + `?phone_number=${email}`);
    }

    public getCountries =  async () => {
        return await axiosInstance.get('/get_countries.php');
    }

    public putUser =  (user: Record<string, any> ) => {
        return axiosInstance.post('/update_user.php', user);
    }

}